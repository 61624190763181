var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('el-main',[_c('div',{staticClass:"smallBox"},[_c('SmallCard',{attrs:{"info":_vm.smallInfoLive},on:{"clickFn":_vm.toAdd}}),_c('SmallCard',{attrs:{"info":_vm.smallInfoGoods},on:{"clickFn":_vm.addGoods}})],1),_c('el-form',{staticClass:"el-form-search",attrs:{"model":_vm.ruleForm,"label-width":"130px"}},[_c('el-form-item',{attrs:{"label":"标题："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入标题"},model:{value:(_vm.ruleForm.title),callback:function ($$v) {_vm.$set(_vm.ruleForm, "title", $$v)},expression:"ruleForm.title"}})],1),_c('el-form-item',{attrs:{"label":"是否显示："}},[_c('el-select',{attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.ruleForm.is_show),callback:function ($$v) {_vm.$set(_vm.ruleForm, "is_show", $$v)},expression:"ruleForm.is_show"}},_vm._l((_vm.is_show_list),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":" ","label-width":"30px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.getDataList(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.clearSearch}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.dataList,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"label":"标题","align":"center","prop":"title"}}),_c('el-table-column',{attrs:{"label":"vr主图","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.picture,"alt":""}})]}}])}),_c('el-table-column',{attrs:{"label":"发布时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDateformat(row.create_time))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"显示状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.is_show === 1 ? '不显示' : '显示')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"浏览量","align":"center","prop":"views_num"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.lookThat(row)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.delThat(row)}}},[_vm._v("删除")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total,"page":_vm.page,"pageNum":_vm.rows},on:{"updatePageNum":_vm.updateData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }