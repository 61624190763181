<template>
  <el-container>
    <el-main>
      <div class="smallBox">
        <SmallCard :info="smallInfoLive" @clickFn="toAdd"></SmallCard>
        <SmallCard :info="smallInfoGoods" @clickFn="addGoods"></SmallCard>
      </div>
      <el-form :model="ruleForm" class="el-form-search" label-width="130px">
        <el-form-item label="标题：">
          <el-input size="small" v-model="ruleForm.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-select size="small" v-model="ruleForm.is_show" placeholder="请选择">
            <el-option v-for="(item, index) in is_show_list" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " label-width="30px">
          <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
          <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column label="标题" align="center" prop="title"></el-table-column>
        <el-table-column label="vr主图" align="center">
          <template v-slot="{ row }">
            <img :src="row.picture" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="发布时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column label="显示状态" align="center">
          <template v-slot="{ row }">
            {{ row.is_show === 1 ? '不显示' : '显示' }}
          </template>
        </el-table-column>
        <el-table-column label="浏览量" align="center" prop="views_num"></el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <el-button type="text" @click="lookThat(row)">查看</el-button>
            <el-button type="text" @click="toEdit(row)">编辑</el-button>
            <el-button type="text" @click="delThat(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </el-container>
</template>

<script>
import SmallCard from '@/components/smallCardBox.vue';
import { imgUrlHead } from '@/util/config';
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
export default {
  components: {
    SmallCard,
    Paging,
  },
  data() {
    return {
      getDateformat,
      imgUrlHead,
      smallInfoLive: {
        img: imgUrlHead + 'vr_01.png',
        title: 'VR设计',
        desc: '高效易用全景互动',
        btnTxt: '去添加',
      },
      smallInfoGoods: {
        img: imgUrlHead + 'vr_02.png',
        title: ' VR 制作',
        desc: '720°重新记录世界',
        btnTxt: '去制作',
      },
      ruleForm: {
        title: '',
        is_show: 0,
      },
      is_show_list: [
        { id: 0, name: '全部' },
        { id: 2, name: '显示' },
        { id: 1, name: '不显示' },
      ],
      page: 1,
      rows: 10,
      total: 0,
      dataList: [],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    clearSearch() {
      this.ruleForm = {
        title: '',
        is_show: 0,
      };
      this.getDataList(1);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getDataList();
    },
    addGoods() {
      window.open('https://720yun.com/');
    },
    toAdd() {
      this.$router.push('/extension/VRadd');
    },

    getDataList(style) {
      if (style) {
        this.page = 1;
      }
      let obj = {
        page: this.page,
        rows: this.rows,
        is_show: this.ruleForm.is_show,
      };
      if (this.ruleForm.title) {
        obj.title = this.ruleForm.title;
      }
      this.$axios.post(this.$api.VR.list, obj).then(res => {
        if (res.code === 0) {
          this.dataList = res.result.list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    lookThat(data) {
      window.open(data.vr_links);
    },
    delThat(data) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.VR.del, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    toEdit(data) {
      this.$router.push('/extension/VRedit?id=' + data.id);
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  width: 100%;
  overflow: auto;
  background-color: #fff;
  .smallBox {
    display: flex;
    align-items: center;
  }
  .el-table {
    img {
      width: 70px;
      height: 100px;
    }
  }
}
</style>
